import React from 'react';
import MoveBox from '../MoveBox.js';
import WebFire from '../WebFire.js';
import PropTypes from 'prop-types';
import CursorState from '../CursorState.js';
import AnimatedText from '../AnimatedText.js';
import Navigation from '../Navigation.js';

export class Home extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.cursorState = new CursorState();
    }

    componentDidMount() {
        var wf = new WebFire({targetId:'testbox360'});
        wf.addFire();

        var newEntry = new AnimatedText({
            text: "2023-03-20<br></br>Do you desire to test your piano skills with the <b>ULTIMATE CHELLANGE</b>? Chellenge yourself to the PIANO GAME with any midi file you desire! Or just chill out and relax while watching colourful vertical lines descend from the top of the screen in <a href='./piano'>PIANO GAME!</a>",
            secondsPerChar: 0.02,
            id: 'new',
        });
        newEntry.show(true, true);
    }

    render() {
        document.body.classList.add("scrollingBackground");
        const handle = <div> </div>;

        const oldContent1 = <div id='old1' className="animated_text_holder">
            <div id='old1' className="animated_text_visible">
                I'm building a web page! Check back soon for great deals, TikTok dances, 
                scandelous celebrity gossip, natural cures that actually work, the news 
                <i>they</i> don't want you to know, gaming tips, koi farming information, 
                reboxing videos and much, much more!<p>In the meantime please excuse the fire.</p>
            </div>
        </div>;

        const oldContent2 = <div id='old1' className="animated_text_holder">
            <div id='old2' className="animated_text_visible">
            2022-10-05<br></br>
            Try out my new <a href='./food'>FOOD TESTER</a> app! Have you ever wondered if food 
            would taste good or bad before you eat it? Wonder no more, as <b>advanced neural 
            network AI</b> powered by <b>DEEP LEARNING</b> will let you know based only on a 
            picture! Never eat a bad meal again!
            </div>
        </div>;

        const newContent = <div id='new' className="animated_text_holder">
        <div id='new' className="animated_text_visible"/>
    </div>;
        return (
            <>
            <Navigation></Navigation>
            <div style={{position: "absolute", width:"1000px", left:"430px", top:"650px"}}>
            <MoveBox
            handle={handle}
            content={oldContent1}
            cursorState={this.cursorState}
            id='oldbox1'
            class='oldbox' />
            </div>

            <div style={{position: "absolute", width:"1000px", left:"300px", top:"450px"}}>
            <MoveBox
            handle={handle}
            content={oldContent2}
            cursorState={this.cursorState}
            id='oldbox2'
            class='oldbox' />
            </div>

            <div style={{position: "absolute", left:"390px", top:"200px"}}>
            <MoveBox
            handle={handle}
            content={newContent}
            cursorState={this.cursorState}
            id='testbox360' />
            </div>
            </>
        );
    }
}
export default Home;