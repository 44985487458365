import React from 'react';
import './navigation.css';

export class Navigation extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="navigation moveBoxHandle moveBox">
                <div className="navTitle">~ Navigation ~</div>
                <ul className="navigationList">
                    <li><a href="./food">FOOD TESTER</a></li>
                    <li><a href="./piano">PIANO GAME</a></li>
                </ul>
            </div>
        );
    }
}
export default Navigation;