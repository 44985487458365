/**
 * A collection of helpful one off functions.
 * 
 * @namespace Helper
 */
class Helper {

    constructor(){}

    /**
     * Shuffles an array using the Fisher�Yates shuffle.
     * 
     * @function 
     * @memberOf Helper
     * @param {mixed[]} array - The array to be shuffled.
     * 
     * @returns shuffled array
     */
    static shuffle(array) {
        var length = array.length;
        var temp;
        var index;
        for (var i = (length - 1); i > 0; i--) {
            index = this.randomInt(0, i);
            temp = array[index];
            array[index] = array[i];
            array[i] = temp;
        }
        return array;
    }

    /**
     * Returns a random integer between min and max inclusive. Numbers are evenly distributed.
     * @function 
     * @memberOf Helper
     * 
     * @param {int} min - The minimum possible value for the random int.
     * @param {int} max - The maximum possible value for the random int.
     * 
     * @returns {int}
     */
    static randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    /**
     * Sums an array
     * @function
     * @memberOf Helper
     * @param {number[]} array - array to be summed.
     * @reutnrs {number} sum
     */
    static sum(array) {
        var sum = 0;
        for (var i = 0, length = array.length; i < length; ++i) {
            sum += array[i];
        }
        return sum;
    }
    /**
     * Subracts any elements which are in both the first and second array from the first array.
     * @function 
     * @memberOf Helper
     * 
     * @param {array} a - All elements in a which are not in b will be returned.
     * @param {array} b - Any elements in b will be removed from a.
     * @returns {array}
     */
    static arraySubtract(a, b) {
        var sum = a.filter(function (element) {
            return b.indexOf(element) < 0;
        });
        return sum;
    }

    /**
     * Get the current date-time formatted as YYYY/MM/DD HH:MM:SS
     * @function 
     * @memberOf Helper
     */
    static getDateTime() {
        var date = new Date();
        return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }

    /**
 * Get the current date-time formatted as YYYY-MM-DD_HH-MM-SS
 * @function 
 * @memberOf Helper
 */
    static getFilenameDateTime() {
        var date = new Date();
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "_" + date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
    }

    /**
     * Removes a single instance of the given value from a copy of the array array
     * @function 
     * @memberOf Helper
     * 
     * @param {array} array - Base array.
     * @param {array} value - The value to be removed from the array copy.
     * @returns {array} a copy of array with value removed.
     */
    static removeValueFromArray(array, value) {
        var index = array.indexOf(value);
        if (index < 0) {
            return array;
        }
        var copy = array.slice(0);
        copy.splice(index, 1);
        return copy
    }

    /**
     * Removes a the current index value from a copy of the given array.
     * @function 
     * @memberOf Helper
     * 
     * @param {array} array - Base array.
     * @param {array} index - The index to be removed from the array copy.
     * @returns {array} a copy of array with index removed.
     */
    static removeIndexFromArray(array, index) {
        var copy = array.slice(0);
        copy.splice(index, 1)
        return copy;
    }

    /**
     * Helps when sorting an array by number (default array sort is as string).
     * @function 
     * @memberOf Helper
     * 
     *  @param {number} a - The first number to be compared.
     *  @param {number} b - The second number to be compared.
     *  @returns {number} The difference between a and b.
     */
    static sortNumbers(a, b) {
        return a - b;
    }

    /**
     * Checks if two arrays are equal.
     * @function 
     * @memberOf Helper
     * 
     * @param {Object[]} a - The first array.
     * @param {Object[]} b - The second array.
     * 
     * @returns {bool} True if equal, false if not equals.
     */
    static checkArraysEqual(a, b) {
        var length = a.length;
        if (length != b.length) {
            return false;
        }
        for (var i = 0; i < length; i++) {
            if (a[i] != b[i]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Calculates the mean of an array of numbers.
     * @function 
     * @memberOf Helper
     * 
     * @param {number[]} a - The array to have its mean found.
     * 
     * @returns {number} The mean value of the array. 
     */
    static arrayMean(a) {
        var length = a.length;
        if (length == 0) {
            return Infinity;
        }
        var sum = 0;
        for (var i = 0; i < length; i++) {
            sum += a[i];
        }
        return sum / length;
    }

    /**
     * Returns a random element from the array
     * @function
     * @memberOf Helper
     * 
     * @param {mixed[]} a - The array to have a random element returned.
     */
    static randomElement(a) {
        var length = a.length;
        return a[this.randomInt(0, length - 1)];
    }

    /**
     * Gets the current date time in yyyy/mm/dd hh:mm:ss notation.
     * 
     * @return {string} date time in yyyy/mm/dd hh:mm:ss notation.
     */
    static getDateTime() {
        var now = new Date();
        var dateTime = now.getFullYear() + "/"
            + (now.getMonth() + 1) + "/"
            + now.getDate() + " "
            + now.getHours() + ":"
            + now.getMinutes() + ":"
            + now.getSeconds();
        return dateTime;
    }

    /**
     * Splits a number into a random array whose values sum to that number
     * @param {number} sum
     * @param {number} pieces - the number of pieces to be split into.
     * @param {number} min - the minimum value of a piece.
     * @param {number} max - the maximum value of a piece.
     * @return {number[]}
     */
    static splitNumber(sum, pieces, min, max) {
        var chunks = [];
        var temp = 0;
        var tempSum = sum;
        var MAX_ATTEMPTS = 99999;
        var valid = false;
        while (!valid) {
            --MAX_ATTEMPTS;
            tempSum = sum;
            chunks = [];
            for (var i = 1; i < pieces; ++i) {
                temp = this.randomInt(min, Math.min(tempSum - (pieces - i) * min, max));
                tempSum -= temp;
                chunks.push(temp);
            }
            if (tempSum <= max) {
                valid = true;
                break;
            }
            if (MAX_ATTEMPTS < 0) {
                throw "split fail";
            }
        }
        chunks.push(tempSum);
        return this.shuffle(chunks);
    }
    /**
     * Splits a number into a random array whose values sum to that number, and tend to be normally distributed. 
     * This is the algorithm used by the original DalCAB.
     * @param {number} sum
     * @param {number} pieces - the number of pieces to be split into.
     * @param {number} min - the minimum value of a piece.
     * @param {number} max - the maximum value of a piece.
     * @return {number[]}
     */
    static normalSplitNumber(sum, pieces, min, max) {
        var chunks = [];
        var temp = 0;
        var valid = false;
        var checksum = 0;
        var MAX_ATTEMPTS = 99999;
        while (!valid) {
            checksum = 0;
            --MAX_ATTEMPTS
            for (var i = 0; i < pieces; ++i) {
                temp = this.randomInt(min, Math.min(max, sum));
                checksum += temp;
                chunks.push(temp);
            }
            if (checksum == sum) {
                valid = true;
                return chunks;
            }
            if (MAX_ATTEMPTS < 0) {
                throw "split fail";
            }
            chunks = [];
        }
    }
    /**
     * Converts keycode to character string
     * @param {number} keycode - The keycode.
     */
     static keycodeToName(keycode) {
        var keycodes = {
            1: "Mouse Button 1",
            2: "Mouse Button 2",
            3: "Mouse Button 3",
            4: "Mouse Button 4",
            5: "Mouse Button 5",
            6: "Mouse Button 6",
            7: "Mouse Button 7",
            8: "Mouse Button 8",
            9: "tab",
            12: 'clear',
            13: "enter",
            16: "shift",
            17: "ctrl ",
            18: "alt",
            19: "pause/break",
            20: "caps lock",
            27: "escape",
            32: "spacebar",
            33: "page up",
            34: "page down",
            35: "end",
            36: "home ",
            37: "left arrow ",
            38: "up arrow ",
            39: "right arrow",
            40: "down arrow ",
            41: "select",
            42: "print",
            43: "execute",
            44: "Print Screen",
            45: "insert ",
            46: "delete",
            48: "0",
            49: "1",
            50: "2",
            51: "3",
            52: "4",
            53: "5",
            54: "6",
            55: "7",
            56: "8",
            57: "9",
            58: ":",
            59: "semicolon (firefox), equals",
            60: "<",
            61: "equals (firefox)",
            63: "ß",
            64: "@ (firefox)",
            65: "a",
            66: "b",
            67: "c",
            68: "d",
            69: "e",
            70: "f",
            71: "g",
            72: "h",
            73: "i",
            74: "j",
            75: "k",
            76: "l",
            77: "m",
            78: "n",
            79: "o",
            80: "p",
            81: "q",
            82: "r",
            83: "s",
            84: "t",
            85: "u",
            86: "v",
            87: "w",
            88: "x",
            89: "y",
            90: "z",
            91: "Windows Key / Left ⌘ / Chromebook Search key",
            92: "right window key ",
            93: "Windows Menu / Right ⌘",
            96: "numpad 0 ",
            97: "numpad 1 ",
            98: "numpad 2 ",
            99: "numpad 3 ",
            100: "numpad 4 ",
            101: "numpad 5 ",
            102: "numpad 6 ",
            103: "numpad 7 ",
            104: "numpad 8 ",
            105: "numpad 9 ",
            106: "multiply ",
            107: "add",
            108: "numpad period (firefox)",
            109: "subtract ",
            110: "decimal point",
            111: "divide ",
            112: "f1 ",
            113: "f2 ",
            114: "f3 ",
            115: "f4 ",
            116: "f5 ",
            117: "f6 ",
            118: "f7 ",
            119: "f8 ",
            120: "f9 ",
            121: "f10",
            122: "f11",
            123: "f12",
            124: "f13",
            125: "f14",
            126: "f15",
            127: "f16",
            128: "f17",
            129: "f18",
            130: "f19",
            131: "f20",
            132: "f21",
            133: "f22",
            134: "f23",
            135: "f24",
            144: "num lock ",
            145: "scroll lock",
            160: "^",
            161: '!',
            163: "#",
            164: '$',
            165: 'ù',
            166: "page backward",
            167: "page forward",
            169: "closing paren (AZERTY)",
            170: '*',
            171: "~ + * key",
            173: "minus (firefox), mute/unmute",
            174: "decrease volume level",
            175: "increase volume level",
            176: "next",
            177: "previous",
            178: "stop",
            179: "play/pause",
            180: "e-mail",
            181: "mute/unmute (firefox)",
            182: "decrease volume level (firefox)",
            183: "increase volume level (firefox)",
            186: "semi-colon / ñ",
            187: "equal sign ",
            188: "comma",
            189: "dash ",
            190: "period ",
            191: "forward slash / ç",
            192: "grave accent / ñ",
            193: "?, / or °",
            194: "numpad period (chrome)",
            219: "open bracket ",
            220: "back slash ",
            221: "close bracket ",
            222: "single quote ",
            223: "`",
            224: "left or right ⌘ key (firefox)",
            225: "altgr",
            226: "< /git >",
            230: "GNOME Compose Key",
            233: "XF86Forward",
            234: "XF86Back",
            255: "toggle touchpad"
        };
        return keycodes[keycode];
    }
}
export default Helper;





