/** CursorState
* tracks the cursor all over the screen, for constant button and position information. 
* At the time it was created it mostly helped with keeping touch and click consistent.
**/
export class CursorState {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.isButtonDown = false;

        document.body.addEventListener("mousemove", (e) => this.mousePosition(e));
        document.body.addEventListener("mousedown", (e) => this.mouseDown(e));
        document.body.addEventListener("mouseup",   (e) => this.mouseUp(e));
        document.body.addEventListener("touchstart",(e) => this.touchDown(e));
        document.body.addEventListener("touchmove", (e) => this.touchDown(e));
        document.body.addEventListener("touchstop", (e) => this.touchUp(e));
    }

    mousePosition(e) {
        this.x = e.clientX;
        this.y = e.clientY;
    }

    mouseDown(e) {
        this.isButtonDown = true;
        this.x = e.clientX;
        this.y = e.clientY;
    }

    touchDown(e) {
        this.isButtonDown = true;
        this.x = e.touches[0].clientX;
        this.y = e.touches[0].clientY;
    }

    mouseUp(e) {
        this.isButtonDown = false;
    }

    touchUp(e) {
        this.isButtonDown = false;
    }
}
export default CursorState;