import { useRouteError } from "react-router-dom";
import React from 'react';
import MoveBox from '../MoveBox.js';
import WebFire from '../WebFire.js';
import PropTypes from 'prop-types';
import CursorState from '../CursorState.js';

class ErrorPage extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.cursorState = new CursorState();
    }

    componentDidMount() {
        var wf = new WebFire({targetId:'errorbox'});
        wf.addFire();
    }

    render() {
        const handle = <div> </div>;
        const content = <div className='movetarget'><p>Oh no, not this page! Run while you still can! <a href="/">Back home!</a></p></div>;
        return (
            <>
            <MoveBox
            handle={handle}
            content={content}
            cursorState={this.cursorState}
            id='errorbox' />
            </>
        );
    }
}
export default ErrorPage;