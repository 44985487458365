/** AnimatedText
 * A delayed text write out like an RPG.
 */
 class AnimatedText {
    /**
     * TODO: pad out individual words with nbsp to prevent mid write rapping.
     * @param {
     *  text - the text to display.
     *  secondsPerChar - seconds between each character displayed.
     *  id - the Id of the shown container text.
     * } args 
     */
    constructor(args) {
        // seconds per character.
        this.targetId = args.targetId;
        this.secondsPerChar = args.secondsPerChar ? args.secondsPerChar : 0.1;
        this.text = args.text;
        this.visibleText = "";
        this.textBox = document.getElementById(args.id);
        this.textBox.innerHTML = "";
        this.position = 0;
        this.currentSecondsPerChar = this.secondsPerChar;
        this.callback = args.callback ? args.callback : undefined;
        this.startTime = 0;
        this.running = false;
        this.shown = false;
        this.animationIndex = undefined;
        if (args.presize) {
            this.sizeContainer();
        }
    }

    newText(text, callback = undefined, presize = true) {
        this.text = text;
        this.callback = callback;
        this.visibleText = "";
        this.position = 0;
        this.currentSecondsPerChar = this.secondsPerChar;
        this.running = false;
        this.shown = false;
        if (presize) {
            this.sizeContainer();
        }
    }

    /**
     * Automatically size the container to its' final size.
     */
    sizeContainer() {
        this.textBox.style.width = "auto";
        this.textBox.style.height = "auto";
        this.textBox.innerHTML = ' ' + this.stripInstructions(this.text);
        this.textBox.style.width = this.textBox.offsetWidth + 'px';
        this.textBox.style.height = this.textBox.offsetHeight + 'px';
        this.textBox.innerHTML = "";
    }

    /**
     * Show the text.
     * @param {bool} size - Presize the container.
     * @param {bool} start - start the text animation right away?
     */
    show(size = true, start = false) {
        if(!this.shown) {
            this.shown = true;
            if (this.animationIndex != undefined) {
                window.cancelAnimationFrame(this.animationIndex);
            }
            if(size) {
                this.sizeContainer();
            }
            if(start) {
                this.start();
            }
        }
    }

    /**
     * Start writing out the text.
     */
    start() {
        this.running = true;
        this.startTime = window.performance.now();
        this.textTime = 0;
        this.animationIndex = window.requestAnimationFrame(() => this._runAnimation());
    }

    /**
     * Runs the animation
     */
    _runAnimation() {
        const elapsedTime = (window.performance.now() - this.startTime) / 1000;
        while (this.textTime < elapsedTime) {
            this.textTime += this.currentSecondsPerChar;
            if (this.cycleText()) {
                this.running = false;
                this.complete();
                return;
            }
        }
        this.animationIndex = window.requestAnimationFrame(() => this._runAnimation());
    }

    /**
     * A text run is complete!
     */
    complete() {
        if (this.callback) {
            this.callback();
        }
    }

    /**
     * Returns the string that will be included in the innerHTML with special instructions removed.
     * @param {string} text 
     */
    stripInstructions(text) {
        var i = 0;
        var cleanText = " ";
        while (i < text.length) {
            var nextInst = text.indexOf('{', i);
            if (nextInst < 0) {
                return cleanText + text.slice(i);
            } else {
                cleanText += text.slice(i, nextInst);
                if (text[nextInst + 1] == '{') {
                    cleanText += text[nextInst + 1];
                    i = nextInst + 2;
                }
                else if (text[nextInst + 1] == '<') {
                    cleanText += text.slice(nextInst+1, text.indexOf('}', i));
                    i = text.indexOf('}', i) + 1;
                } else {
                    i = text.indexOf('}', i) + 1;
                }
            }
        }
        return cleanText;
    }

    /**
     * Special instructions start with a '{' and end with a '}'. to print a '{' use '{{'
     * @param {string} instruction 
     */
    executeSpecialInstruction(instruction) {
        const newSpeed = parseFloat(instruction);
        if (instruction[0] == '<') { //try inserting an HTML tag!
            this.visibleText += instruction;
        } else if (!isNaN(newSpeed)) {
            this.currentSecondsPerChar = newSpeed;
        }
    }

    extractInstruction(start) {
        return this.text.slice(start, this.text.indexOf('}', start));
    }

    /**
     * stop words from suddenly wrapping by buffering with non breaking white spaces. Needs some work so isn't currently used.
     */
    getWordNBWSBuffer() {
        var nextSpace = this.text.indexOf(' ', this.position);
        var blankBuffer = '';
        if (nextSpace < 1) {
            return '';
        } 
        for(var i = this.position; i < nextSpace; i++) {
            blankBuffer += '&nbsp;';
        }
        return blankBuffer;
    }

    /**
     * Performs action on next character
     * @returns complete - true if complete, false if incomplete.
     */
    cycleText() {
        if (this.position >= this.text.length)
        {
            return true;
        }
        var nextChar = this.text[this.position];
        if(nextChar == '{' && this.text[this.position+1] != '{') {
            // handle special instruction
            const instruction = this.extractInstruction(this.position+1);
            this.executeSpecialInstruction(instruction);
            this.position = this.text.indexOf('}', this.position) + 1;
        } 
        // just print character
        if (this.position >= this.text.length)
        {
            return true;
        }
        this.visibleText += this.text[this.position];
        this.textBox.innerHTML = this.visibleText;
        if (this.text[this.position] == '{') { 
            this.position++;
        }

        this.position++;
        return this.position >= this.text.length;
    }
}
export default AnimatedText;