import React from "react";
import ReactDOM from "react-dom/client";
import CursorState from './CursorState.js';
import Home from './pages/Home.js';
import FoodTest from './pages/FoodTest.js';
import ErrorPage from './pages/error-page.js';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

// ========================================
var CURSORSTATE = new CursorState();
//var scroll = new BackgroundScroller({element: document.body, speed:{x: -0.04, y:0.01}});
//scroll.startAnimation();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "food",
    element: <FoodTest />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


