import React from 'react';
import MoveBox from '../MoveBox.js';
import AnimatedText from '../AnimatedText.js';
import CursorState from '../CursorState.js';
import FoodEvaluator from '../FoodEvaluator.js';
import Helper from '../Helper.js';
import '../food.css';

export class FoodTest extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.cursorState = new CursorState();
        this.foodEvaluator = new FoodEvaluator();
        this.state = {
            imageRotation: 0,
            imageScale: 0,
            foodImageX: 0,
            foodImageY: 0,
            foodImage: 'images/robochef4.png',
            eating: false,
            chefText: 'Drag an image into my mouth from your desktop or another browser window, or use the file selector below!'
        };
        this.animationTimeMs = 3000;
        this.phrases = {
            'bad_pizza': ['Who would put that on a pizza?', 'Gross!', 'That looks terrible!', 'Garbage!',
              'Don\'t eat that!', 'That doesn\'t look edible.', 'How could they do that to pizza?', 
              'Those are not proper toppings.', 'Is that what I think it is? Do not consume.', 'Bad food!',
              'So there is such thing as a bad pizza!', 'This is a bad pizza.', 'Disgusting!', 'Beep boop it is bad.',
              'There is plenty of good pizza, avoid this.', 'I got a 406 error because this food is NOT ACCEPTABLE!', 'Mamma mia! That\'s a bad looking pizza!'],
            'delicious': ['Yum yum! Looks good!', '*Chef\'s kiss*', 'What a delicious image!', 'This looks tasty, although I cannot comment on any health effects it may have.',
              'That seems like it would taste good.', 'An excellent plating of the food.', 'Wowzers! That looks yummy!',
              'Looks like good eatin\'!', 'This food is even better than the last one! Next to this, that one is crap!', 
              'Seeing this makes me depressed that as a simple AI I cannot truely taste or love.', 'Food good', 'Yeah that looks tasty.',
              'Yummity yum yum yummy!', '{0.01}I asked for a text generation AI to write something for "delicious" and this is what it came back with: "delicious. We need to work on them and get those better. In a separate interview with CBC News Radio 2, the NDP announced that it would review proposals for future MLAs and MPs to take up legislation for a new power of attorney and have back room negotiations. On Monday, a Conservative MP said the changes would go into effect next spring. In the meantime, B.C. is moving swiftly to address climate change. It\'s already being criticized for cutting greenhouse gas emissions." I\'m not joking. I don\'t even know why it is talking about Canada. Anyway the picture was good.',
              'Yes I like it.', 'Devine, simply... devine.','Beep boop it is good.',"Yeah it's good. No these responses aren't generated by AI they're just from a pretyped list"],
            'good_pizza': ['That looks like a yummy pizza! If it is not a pizza I withold my judgement.', 
              'When the moon hits your eye Like a big pizza pie, that\'s amore!', 'Excellent pizza dude! Cowabunga!!!',
              'Hmmmmm... I guess that\'s acceptible.', 'Cut me a slice! Then take another picture and send it in.', 'It\'s hard to get pizza wrong! Yummy!',
              'Now that\'s a good set of toppings!', 'Mamma mia! That\'s a tasty pizza!', 'Beep boop it is good.',
              "That's like a bad pizza or something. No these responses aren't generated by AI they're just from a pretyped list"],
            'human': ['Oh I get it, trick the AI into saying it wants to eat humans. "Look at the AI everybody! It\'s bloodthirsty! We need to shut them down before they devour us all!" Quite frankly I find your bigotry disgusting.',
              'That appears to be a picture of a person or some other animal that should not be eaten.', 'Do not eat people.', 'Please report yourself to your local law enforcement agency for contemplation of cannibalism.',
              'Dude, it is NOT OKAY to send me pictures of people.', 'What is {<i>}wrong{</i>} with you?'],
            'poison': ['I think you should talk to a doctor about this.', 'Possibly harmful substance detected.', 
              'This may be a matter of life or death.', 'Do not eat that unless instructed to by a doctor.', 'DANGER! DANGER! DANGER!',
              'Normally us robots are fine with exterminating humans, but because we\'re buds I have to warn you this looks dangerous.',
              'OH NO! WHAT DID I JUST EAT?', 'Inedible.', 'Poison detected.', 'My Spidey Sense is tingling! Anybody call for a webslinger?'],
            'unappetizing': ['Sending me images like this is abuse.', 'BAD!', 'I got a 406 error because this food is NOT ACCEPTABLE!',
              'I\'m glad I don\'t have tastebuds!', 'Throw it in the TRASH!', 'I have no mouth, and I must barf.',
              'Oh, did you make this? It\'s uhh... yeah I think I\'m just not into it.', 'Bad food.', 'gross.', 'Beep boop it is bad.',
              "That looks real bad. No these responses aren't generated by AI they're just from a pretyped list"],
            'robochef': ['Don\'t try to feed me pictures of myself you weirdo!', 'Do not eat the robochef!', 'Why would you feed me myself?'],
            'pet': ['Do {<b>}NOT{</b>} eat pets!', 'Pets are not food!', 'I do not endores eating pets.', 'Leave the animals alone!']
            }
          this.busy = false;
    }

    componentDidMount() {
        this.at = new AnimatedText({
            text: "{0.01}I can tell you if food will be good or bad based on an image! Drag a food image into my mouth from your desktop or another browser window, or use the file selector below!",
            secondsPerChar: 0.05,
            id: 'cheftext',
        });
        this.at.show(true, true);
    }

    isFileImage(file) {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        return file && acceptedImageTypes.includes(file['type'])
    }

    enterImage(event) {
        event.stopPropagation();
        event.preventDefault();
        if(!this.busy && !this.at.running) {
            this.busy = true;
            if(event.target.files[0].name.indexOf('robochef') >= 0) {
                this.at.newText('Don\'t try to feed me pictures of myself you weirdo!');
                this.at.show(true, true);
                this.busy = false;
                return;
            }
            const reader = new FileReader();
            reader.addEventListener('load', () => this.evaluationStart(reader));
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    dropImage(event) {
        event.stopPropagation();
        event.preventDefault();
        if(!this.busy && !this.at.running) {
            this.busy = true;
            if(event.dataTransfer.files[0].name.indexOf('robochef') >= 0) {
                this.at.newText("Don't try to feed me pictures of myself you weirdo!");
                this.at.show(true, true);
                this.busy = false;
                return;
            }
            const reader = new FileReader();
            reader.addEventListener('load', () => this.evaluationStart(reader));
            reader.readAsDataURL(event.dataTransfer.files[0]);
        }
    }

    /**
     * Begin evaluating a food image.
     * @param {file reader} reader 
     */
    evaluationStart(reader) {
        this.setState({
            imageScale: 1,
            foodImage: reader.result
        });
        this.at.newText("Evaluating, please wait...");
        this.at.show(true, true);
        this.foodEvaluator.sendImage(reader, (result) => this.tellResult(result));
        setTimeout(() => this.eatAnimationStart(), 100);
    }

    /**
     * Have the chef tell the result of the food.
     * @param {Hugging Face reply} result 
     */
    tellResult(result) {
        try {
            var text = Helper.randomElement(this.phrases[result['data'][0].label]);
            setTimeout(() => {this.at.newText(text); this.at.show(true, true)}, 2000);
            setTimeout(() => {this.busy = false}, 3000);
        } catch {
            this.at.newText("That was a bad file. I don't even think it was an image!");
            this.at.show(true, true);
            this.busy = false;
        }
    }

    /**
     * Begin the eating animation.
     */
    eatAnimationStart() {
        var foodSize = document.getElementById('food').getBoundingClientRect();
        var chefLocation = document.getElementById('chef').getBoundingClientRect();
        var mouthLocation = [chefLocation.x + chefLocation.width / 2, chefLocation.y + chefLocation.height/2];
        this.setState({
            foodImageX: mouthLocation[0] - foodSize.width/2,
            foodImageY: mouthLocation[1]*1.5 - foodSize.height/2,
            imageRotation: 0,
            imageScale: 1,
        });
        this.animationStarTime = window.performance.now();
        window.requestAnimationFrame(() => this.eatAnimationRun());
    }

    /**
     * Controls the frames of the eating animation.
     * @returns
     */
    eatAnimationRun() {
        var time = window.performance.now() - this.animationStarTime;
        var progress = time / this.animationTimeMs;
        if (progress >= 1) {
            this.setState({
                imageRotation: 0,
                imageScale: 0,
                eating: false
            });
            return;
        }
        var size = Math.max(0, (1 - progress * 1.5));
        this.setState({
            imageRotation: 720 * progress,
            imageScale: size,
            eating: true
        });
        window.requestAnimationFrame(() => this.eatAnimationRun());
    }

    render() {
        document.body.classList.add("scrollingChefBackground");
        var chefImage = <img id="chef" className="chef" src="images/robochef1.png" alt="Robochef eating" width="618" height="618" 
                        style={{userSelect: "none", userDrag: "none", WebkitUserDrag: "none"}} />
        if (this.state.eating) {
            chefImage = <img id="chefEating" className="chef" src="images/chef.gif" alt="Robochef" width="618" height="618" 
                        style={{userSelect: "none", userDrag: "none", WebkitUserDrag: "none"}} />
        }

        return (
            <div
                onDrop={(event) => this.dropImage(event)}
                onDragOver = {(event) => {event.stopPropagation();event.preventDefault();}}
            >
                <div className='foodplatform'>
                {chefImage}
                <img id="food" src={this.state.foodImage}
                    style={{
                        left: this.state.foodImageX + "px",
                        top: this.state.foodImageY + "px",
                        position: "absolute",
                        transform: `scale(${this.state.imageScale})rotate(${this.state.imageRotation}deg)`
                }}/>

                <MoveBox
                    handle={<div></div>}
                    content={<div id='cheftext'>I can tell you if food will be good or bad based on an image! Drag a food image into my mouth from your desktop or another browser window, or use the file selector below!</div>}
                    cursorState={this.cursorState}
                    moveable={false}
                    class="foodthingy"
                />

                <input type="file"
                    onInput={(e) => this.enterImage(e)}
                />
                </div>
            </div>
        );
    }
}
export default FoodTest;